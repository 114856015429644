import mixpanel from 'mixpanel-browser';

// Near entry of your product, init Mixpanel
mixpanel.init(process.env.MIXPANEL_TOKEN, {
  debug: true, //process.env.GATSBY_ENV.indexOf('dev') > -1,
  track_pageview: true,
  persistence: 'localStorage',

  // whether to ignore or respect the web browser's Do Not Track setting
  ignore_dnt: true,
});

export default mixpanel;
